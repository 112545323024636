import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchOffers(page = 1, filtersObject = {}) {
  const { filtersCopy } = processFilters(filtersObject);
  let requestParams = {
    filters: { ...filtersCopy },
    page,
  };
  return $http.get(`/admin/offers`, {
    params: requestParams,
    headers: authHeader(),
  });
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters["entity_id"]) {
    filters["entity_ids"] = [filters["entity_id"]];
    delete filters["entity_id"];
  }
  if (filters["product_id"]) {
    filters["product_ids"] = [filters["product_id"]];
    delete filters["product_id"];
  }
  if (filters["unit_id"]) {
    filters["unit_ids"] = [filters["unit_id"]];
    delete filters["unit_id"];
  }
  if (filters["entity_name"]) {
    filters["entity_ids"] = [filters["entity_name"]];
    delete filters["entity_name"];
  }
  if (filters["promoter"]) {
    if (filters["promoter"].length == 0) {
      delete filters.promoter;
    }
  }
  if (filters["areas"]) {
    if (filters["areas"].length == 0) {
      delete filters.areas;
    }
  }
  if (filters.start_date === null) {
    delete filters.start_date;
  }
  if (filters.end_date === null) {
    delete filters.end_date;
  }

  if (filters["start_date"]) {
    filters.start_date = new Date(filters.start_date).getTime();
  }
  if (filters["end_date"]) {
    filters.end_date = new Date(filters.end_date).getTime();
  }
  let filtersCopy = filters;

  return { filtersCopy };
}

function importOffersSheet(toBeSentFormData, action) {
  if (action === "assign" || action === "unassign") {
    return $http.post(
      `/admin/offers/import-offers-assignation?action=${action}`,
      toBeSentFormData,
      {
        headers: authHeader(),
        responseType: "arraybuffer",
      }
    );
  }
  return $http.post(`/admin/offers/import?action=${action}`, toBeSentFormData, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}

function exportOffersSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  let requestParams = {
    filters: { ...filtersCopy },
    action,
  };
  return $http.get(`/admin/offers/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function exportUsersOffersSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  let requestParams = {
    filters: { ...filtersCopy },
    action,
  };
  return $http.get(`/admin/offers/export-user-assignation-template`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function addOffer(requestBody) {
  return $http.post(`/admin/offers`, requestBody, {
    headers: authHeader(),
  });
}
function editOffer(offerId, requestBody) {
  return $http.put(`/admin/offers/${offerId}`, requestBody, {
    headers: authHeader(),
  });
}
export const offersService = {
  fetchOffers,
  importOffersSheet,
  exportOffersSheet,
  exportUsersOffersSheet,
  addOffer,
  editOffer,
};
