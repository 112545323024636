<template>
  <div>
    <el-dialog :title="`Add New Target`" :visible.sync="localVisible">
      <el-form
        :rules="rules"
        :model="dialogFormData"
        ref="targetsCreateFromRef"
      >
        <!-- @change="
              dropdownChanged('type', ['discrepancy', 'min_order_amount'])
            " -->
        <el-form-item
          :label="`Target Gift Type`"
          key="target_gift_type"
          prop="target_gift_type"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.target_gift_type"
            @change="targetGiftTypeChanged"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="p in gitTypeValueDropdownData"
              :key="p.value"
              :label="p.label"
              :value="p.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template
          v-if="
            dialogFormData.target_gift_type &&
            dialogFormData.target_gift_type == 'dynamic'
          "
        >
          <el-form-item
            key="multi_achieved"
            label="Multiple Achieving"
            prop="multi_achieved"
            label-width="200px"
          >
            <el-switch v-model="dialogFormData.multi_achieved" />
          </el-form-item>
        </template>
        <!--         
         <el-option
              v-for="p in receiptValueDropdownData"
              :key="p.value"
              :label="p.label"
              :value="p.value"
            >
            </el-option> -->
        <el-form-item
          :label="`Target Type`"
          prop="type"
          label-width="160px"
          key="targetType"
        >
          <el-select
            v-model="dialogFormData.type"
            filterable
            style="width: 100%"
            @change="
              dropdownChanged('type', ['discrepancy', 'min_order_amount'])
            "
          >
            <el-option
              :disabled="dialogFormData.target_gift_type == 'dynamic'"
              label="Receipt Value"
              value="receipt_value"
            >
            </el-option>
            <el-option label="Receipt Count" value="receipt_count"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          key="discrepancy"
          :label="`Discrepancy`"
          prop="discrepancy"
          label-width="160px"
        >
          <el-input
            type="number"
            v-model="dialogFormData.discrepancy"
            autocomplete="off"
            :disabled="
              !(dialogFormData.type && dialogFormData.type == 'receipt_value')
            "
            @change="dropdownChanged('discrepancy', [])"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          key="min_order_amount"
          :label="`Min. Order`"
          prop="min_order_amount"
          label-width="160px"
        >
          <el-input
            type="number"
            v-model="dialogFormData.min_order_amount"
            autocomplete="off"
            :disabled="
              !(dialogFormData.type && dialogFormData.type == 'receipt_count')
            "
          >
          </el-input>
        </el-form-item>
        <el-form-item
          :label="`Target Value`"
          prop="value"
          label-width="160px"
          key="targetValue"
        >
          <el-input
            type="number"
            v-model="dialogFormData.value"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <div
          v-if="discrepancyAmount"
          class="targets-dialog__discrepancy-message"
        >
          NB: The Discrepancy between the Target Value and Discrepancy is
          {{ discrepancyAmount }}%
        </div>
        <el-form-item
          :label="`Payment Method`"
          prop="payment_method"
          label-width="160px"
          key="payment_method"
        >
          <el-select
            v-model="dialogFormData.payment_method"
            clearable
            filterable
            style="width: 100%"
            @change="dropdownChanged('payment_method', [])"
          >
            <el-option
              v-for="p in paymentMethodDropdownData"
              :key="p.value"
              :label="p.label"
              :value="p.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`Sections`"
          prop="sections"
          label-width="160px"
          key="sections"
        >
          <dropdownSelectAll
            ref="wholesalersDropdown"
            v-model="dialogFormData.sections"
            :source="allSections"
            :placeholder="`Select Sections`"
            @change="sectionsChosen"
          >
            <template v-slot:optionBody>
              <el-option
                v-for="item in allSections"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </template>
          </dropdownSelectAll>
        </el-form-item>
        <template
          v-if="
            dialogFormData.target_gift_type &&
            dialogFormData.target_gift_type == 'dynamic'
          "
        >
          <el-form-item
            key="t0NewUsers"
            label="T-0 new users"
            prop="t0NewUsers"
            label-width="200px"
          >
            <el-switch v-model="dialogFormData.t0NewUsers" />
          </el-form-item>
          <el-form-item label="Select Regions" label-width="200px">
            <div class="u-display-flex u-align-items--center">
              <dropdownCategoriesSelect
                class="targetRegionsWrapper"
                @change="regionsChanged"
                v-model="dialogFormData.regions"
              />
              <el-button
                class="u-left-margin--2x"
                v-if="showAreaFilters"
                type="primary"
                size="small"
                @click="filterAreasByRegion"
                >Filter Areas</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            prop="targetsAreas"
            label="Select Areas"
            label-width="200px"
          >
            <dropdownSelectAll
              v-model="dialogFormData.targetsAreas"
              :source="
                dialogFormData.areasList.length
                  ? dialogFormData.areasList
                  : lookups.areas
              "
              :placeholder="$t('handleAdsPagePlaceholderSelectAreas')"
              clearable
              filterable
            >
              <template
                v-slot:optionBody
                v-if="!dialogFormData.areasList.length"
              >
                <el-option
                  v-for="item in lookups.areas"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </template>
              <template v-slot:optionBody v-else>
                <el-option
                  v-for="item in dialogFormData.areasList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </template>
            </dropdownSelectAll>
          </el-form-item>
        </template>

        <el-form-item
          key="select_all_suppliers"
          :label="`Select all Suppliers`"
          prop="select_all_suppliers"
          label-width="200px"
        >
          <el-switch
            @change="dataEraser(['included_providers', 'excluded_providers'])"
            v-model="dialogFormData.selectAllSuppliersFlag"
          />
        </el-form-item>
        <el-form-item
          key="included_providers"
          :label="`Assign to Suppliers`"
          prop="included_providers"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.included_providers"
            clearable
            filterable
            remote
            multiple
            :remote-method="filterData"
            :loading="loading"
            style="width: 100%"
            @change="(obj) => dropdownChanged('included_providers', [], obj)"
            :disabled="dialogFormData.selectAllSuppliersFlag"
          >
            <el-option
              v-for="p in filteredSuppliersData"
              :key="p.id"
              :label="p.name"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          key="excluded_providers"
          :label="`Excluded Suppliers`"
          prop="excluded_providers"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.excluded_providers"
            clearable
            filterable
            remote
            multiple
            :remote-method="filterData"
            :loading="loading"
            style="width: 100%"
            @change="(obj) => dropdownChanged('excluded_providers', [], obj)"
            :disabled="!dialogFormData.selectAllSuppliersFlag"
          >
            <el-option
              v-for="p in filteredSuppliersData"
              :key="p.id"
              :label="p.name"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex">
          <el-form-item
            :label="`Start Date`"
            prop="start_date"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.start_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`End Date`"
            prop="end_date"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.end_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item
          :label="`Target Icon`"
          prop="icon"
          label-width="160px"
          key="targetIcon"
        >
          <el-select
            v-model="dialogFormData.icon"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(p, index) in targetIconDropdownData"
              :key="index"
              :label="p"
              :value="p"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template
          v-if="
            dialogFormData.target_gift_type &&
            dialogFormData.target_gift_type == 'static'
          "
        >
          <el-form-item
            :label="`Gift Type`"
            prop="gift_type"
            key="gift_type"
            label-width="160px"
          >
            <el-select
              v-model="dialogFormData.gift_type"
              clearable
              filterable
              style="width: 100%"
              @change="dropdownChanged('gift_type', [])"
            >
              <el-option
                v-for="p in giftTypeDropdownData"
                :key="p.value"
                :label="p.label"
                :value="p.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item
          v-if="
            dialogFormData.gift_type &&
            dialogFormData.gift_type == 'promocode' &&
            dialogFormData.target_gift_type == 'static'
          "
          :label="`Promocode`"
          prop="promocode_id"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.promocode_id"
            clearable
            filterable
            remote
            :remote-method="searchPromocodes"
            :loading="loading"
            style="width: 100%"
          >
            <el-option
              v-for="p in fetchedPromocodes"
              :key="p.id"
              :label="p.code"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          v-if="
            dialogFormData.gift_type && dialogFormData.gift_type == 'promocode'
          "
          :label="`Promocode Validity`"
          prop="promocode_validity"
          label-width="160px"
        >
          <el-input
            type="number"
            v-model="dialogFormData.promocode_validity"
            autocomplete="off"
          >
          </el-input>
        </el-form-item> -->

        <template
          v-if="
            dialogFormData.gift_type &&
            dialogFormData.gift_type == 'physical' &&
            dialogFormData.target_gift_type == 'static'
          "
        >
          <el-form-item :label="`Text`" prop="gift_text" label-width="160px">
            <el-input v-model="dialogFormData.gift_text" autocomplete="off" />
          </el-form-item>

          <el-form-item :label="`Image`" prop="gift_image" label-width="160px">
            <el-input v-model="dialogFormData.gift_image" autocomplete="off" />
          </el-form-item>
        </template>

        <el-form-item
          :label="`Target Conditions`"
          prop="conditions"
          key="conditions"
          label-width="160px"
        >
          <Picker
            :native="true"
            :showCategories="true"
            :showPreview="true"
            :style="{
              position: 'absolute',
              left: ' 20%',
              'z-index': '99999',
              display: pickerShown ? 'flex' : 'none',
            }"
            @select="addEmoji($event, 'markdownEditorConditions')"
          />
          <Editor
            @load="onEditorLoad($event, 'markdownEditorConditions')"
            ref="markdownEditorConditions"
            height="250px"
            initialEditType="wysiwyg"
            :options="editorOptions"
          />
        </el-form-item>
        <el-form-item
          :label="`Description`"
          prop="description"
          key="description"
          label-width="160px"
        >
          <el-input v-model="dialogFormData.description" autocomplete="off">
          </el-input>
        </el-form-item>

        <template
          v-if="
            dialogFormData.target_gift_type &&
            dialogFormData.target_gift_type == 'dynamic'
          "
        >
          <el-header class="custom-header">
            <h3>Auto Generated PromoCode Configurations</h3>
            <el-divider></el-divider>
          </el-header>
          <el-form-item
            label="PromoCode Value"
            prop="promocode_value"
            label-width="160px"
          >
            <el-input
              type="number"
              v-model="dialogFormData.promocode_value"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="Promo Min Amount"
            prop="promocode_min_amount"
            label-width="160px"
          >
            <el-input
              type="number"
              v-model="dialogFormData.promocode_min_amount"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label="Gift Redemption"
            label-width="140px"
            size="normal"
          >
            <el-radio-group
              v-model="dialogFormData.dynamic_promocode_gift_redemption"
              size="medium"
            >
              <el-radio-button label="all">All</el-radio-button>
              <el-radio-button label="purchased_wholesaler"
                >Purchased Wholesaler</el-radio-button
              >
              <el-radio-button label="target_wholesaler"
                >Target Wholesaler</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="Duration" label-width="100px" size="normal">
                <el-radio-group v-model="dialogFormData.duration" size="medium">
                  <el-radio-button label="countOfDays"
                    >Count Of Days</el-radio-button
                  >
                  <el-radio-button label="specificPeriod"
                    >Specific Period</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <div class="ad-card_body-col">
                <template v-if="dialogFormData.duration == 'countOfDays'">
                  <el-form-item
                    label="Numbers Of Days"
                    prop="number_of_days"
                    label-width="166px"
                  >
                    <el-input
                      type="number"
                      v-model="dialogFormData.number_of_days"
                      autocomplete="off"
                    >
                    </el-input>
                  </el-form-item>
                </template>

                <template v-if="dialogFormData.duration == 'specificPeriod'">
                  <div style="display: flex">
                    <el-form-item
                      :label="`Start Date`"
                      prop="start_date"
                      label-width="160px"
                      style="flex-basis: 50%"
                    >
                      <el-date-picker
                        v-model="dialogFormData.dynamic_promocode_start_date"
                        type="datetime"
                        placeholder="Select date and time"
                        value-format="timestamp"
                        style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>

                    <el-form-item
                      label-width="160px"
                      :label="`End Date`"
                      prop="end_date"
                      style="flex-basis: 50%"
                    >
                      <el-date-picker
                        v-model="dialogFormData.dynamic_promocode_end_date"
                        type="datetime"
                        placeholder="Select date and time"
                        value-format="timestamp"
                        style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </template>
              </div>
            </el-col>
          </el-row>
        </template>

        <!-- new end -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="createTarget">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { providersService } from "@/services/providers.service.js";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import { Picker } from "emoji-mart-vue";
// import customValidators from "../../../utils/customValidators";
import {
  receiptValueDropdownData,
  gitTypeValueDropdownData,
  paymentMethodDropdownData,
  // targetIconDropdownData,
  giftTypeDropdownData,
  defaultCreateData,
  editorOptions,
} from "./targets.data";
import dropdownSelectAll from "../../../components/dropdownSelectAll.vue";
import { sectionsService } from "@/services/sections.service";
import { promocodesService } from "@/services/promocodes.service";
import { targetsService } from "@/services/targets.service";
import { regionsService } from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";
import dropdownCategoriesSelect from "../../../components/dropdownCategoriesSelect.vue";
function createLastButton(handler) {
  const button = document.createElement("button");
  button.className = "toastui-editor-toolbar-icons last";
  button.style.backgroundImage = "none";
  button.style.margin = "0";
  button.innerHTML = `<i class="el-icon-bicycle"></i>`;
  button.addEventListener("click", (e) => {
    handler();
    e.preventDefault();
  });
  return button;
}

export default {
  name: "TargetCreateDialog",
  components: {
    Editor,
    Picker,
    dropdownSelectAll,
    dropdownCategoriesSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    iconConfigList: {
      default: () => [],
    },
  },
  data() {
    // let discValidator = (rule, value, callback) => {
    //   if (value < 0) {
    //     callback(new Error("Price should be bigger than 0 or equal"));
    //   } else {
    //     if (this.dialogFormData.value) {
    //       const targetValue = parseInt(this.dialogFormData.value);
    //       const discValue = parseInt(value);
    //       if (discValue >= targetValue) {
    //         callback(
    //           new Error("Discrepancy should be less than the target value")
    //         );
    //         return;
    //       }
    //       return callback();
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    // let arrayLengthValidator = (rule, value, callback) => {
    //   if (value || !value.length) {
    //     callback(new Error("At least one item should be chosen"));
    //   }
    //   callback();
    // };
    let positiveValue = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error("Please input positive value"));
      } else {
        callback();
      }
    };
    return {
      localVisible: false,
      rules: {
        type: [
          {
            required: true,
            message: "Please select a target type",
            trigger: "blur",
          },
        ],
        target_gift_type: [
          {
            required: true,
            message: "Please select a Gift Type",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "Please input target value",
            trigger: "blur",
          },
          {
            validator: positiveValue,
            trigger: "blur",
          },
        ],
        min_order_amount: [
          {
            required: false,
            message: "Please input minimum order value",
            trigger: "blur",
            // validator: customValidators.onlyPositiveNumbervalidator,
          },
          {
            validator: positiveValue,
            trigger: "blur",
          },
        ],
        discrepancy: [
          {
            required: false,
            message: "Please input discrepancy",
            trigger: "blur",
            // validator: discValidator,
          },
        ],
        number_of_days: [
          {
            required: true,
            message: "Please input Number of Days",
            trigger: "blur",
            // validator: discValidator,
          },
          {
            validator: positiveValue,
            trigger: "blur",
          },
        ],
        promocode_min_amount: [
          {
            required: true,
            message: "Please input PromoCode Min Amount",
            trigger: "blur",
            // validator: discValidator,
          },
          {
            validator: positiveValue,
            trigger: "blur",
          },
        ],
        promocode_value: [
          {
            required: true,
            message: "Please input PromoCode Value",
            trigger: "blur",
            // validator: discValidator,
          },
          {
            validator: positiveValue,
            trigger: "blur",
          },
        ],
        sections: [
          {
            type: "array",
            required: true,
            message: "Please select sections",
            trigger: "blur",
            // validator: arrayLengthValidator,
          },
        ],
        targetsAreas: [
          {
            type: "array",
            required: false,
            message: "Please select at least One Area",
            trigger: "change",
          },
        ],
        start_date: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
        ],
        dynamic_promocode_start_date: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
        ],
        end_date: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
        ],
        dynamic_promocode_end_date: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
        ],
        gift_type: [
          {
            required: true,
            message: "Please select gift type",
            trigger: "blur",
          },
        ],
        payment_method: [
          {
            required: true,
            message: "Please select payment method",
            trigger: "blur",
          },
        ],
        gift_image: [
          {
            required: true,
            message: "Please input gift image",
            trigger: "blur",
          },
        ],
        gift_text: [
          {
            required: true,
            message: "Please input gift text",
            trigger: "blur",
          },
        ],
      },
      defaultCreateData: defaultCreateData,
      dialogFormData: null,
      filteredSuppliersData: [],
      loading: false,
      fetchedProducts: [],
      fetchedProductUnits: [],
      pickerShown: false,
      editorOptions: editorOptions,
      receiptValueDropdownData,
      gitTypeValueDropdownData,
      paymentMethodDropdownData,
      possibleSuppliers: [],
      targetIconDropdownData: [],
      giftTypeDropdownData,
      fetchedPromocodes: [],
      showAreaFilters: false,
    };
  },
  watch: {
    "dialogFormData.t0NewUsers"(newVal) {
      if (newVal) {
        this.rules.targetsAreas[0].required = true;
      } else {
        this.rules.targetsAreas[0].required = false;
      }
    },
    localVisible(value, oldValue) {
      if (value === false) {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        this.$refs["targetsCreateFromRef"].resetFields();
      }
      if (value !== oldValue) {
        this.$emit("input", value);
      }
    },
    value(newVal) {
      this.localVisible = newVal;
      this.targetIconDropdownData = this.iconConfigList;
    },
    iconConfigList(newValue) {
      this.targetIconDropdownData = newValue;
    },
  },
  created() {
    this.dialogFormData = { ...this.defaultCreateData };
    this.localVisible = this.value;
  },
  methods: {
    regionsChanged(regions) {
      this.showAreaFilters = regions.length && true;
      if (regions.length === 0) this.dialogFormData.areasList = [];
    },
    async filterAreasByRegion() {
      try {
        const {
          data: {
            data: { areas },
          },
        } = await regionsService.getAreasLookup({
          filter: { regionId: this.dialogFormData.regions },
        });
        this.dialogFormData.areasList = areas.map((a) => ({
          id: a.key,
          name: a.value,
        }));
        this.dialogFormData.targetsAreas = areas.map((a) => a.key);
        this.showAreaFilters = false;
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    targetGiftTypeChanged(val) {
      if (val === "dynamic") {
        this.dialogFormData.type = "receipt_count";
      }
    },
    async sectionsChosen(obj) {
      try {
        // this.$loading();
        setTimeout(() => {
          this.dropdownChanged("sections", [
            "included_providers",
            "excluded_providers",
          ]);
        }, 100);

        let filterBody = {
          sectionKeys: obj,
        };
        const response = await sectionsService.getSectionsProviders(filterBody);
        // console.log("response :>> ", response);
        const fetchedProviders = response.data?.data?.providers;
        this.possibleSuppliers = fetchedProviders
          ? JSON.parse(JSON.stringify(fetchedProviders))
          : [];
        this.filteredSuppliersData = fetchedProviders
          ? JSON.parse(JSON.stringify(fetchedProviders))
          : [];
        
      } catch (error) {
        console.log("error :>> ", error);
      }
      // this.$loading().close();
      // console.log("obj :>> ", obj);
    },
    async createTarget() {
      this.$refs["targetsCreateFromRef"].validate(async (isValid) => {
        try {
          if (isValid) {
            const apiObject = this.buildRequestBody();

            const response = await targetsService.addTarget(apiObject);
            if (response && response.status == 200) {
              this.$globalFunctions.popupMessageWrapper(
                "Target created successfully",
                "success",
                1500
              );
              setTimeout(() => {
                this.$refs.markdownEditorConditions.invoke("setMarkdown", "");
                // this.$refs.markdownEditorDescription.invoke("setMarkdown", "");
                this.$emit("targetCreated");
              }, 1300);
            }
          }
        } catch (error) {
          console.log("createTargetError", error);
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
    },
    filterData(text) {
       try {
        this.loading = true;
        let finalArray = this.possibleSuppliers.filter((singleItem) => {
          if (text.length > 0) {
            return singleItem.name.toLowerCase().match(text.toLowerCase());
          }
          return false;
        });
        this.filteredSuppliersData = finalArray;
        this.loading = false;
      } catch (error) {
         this.loading = false;
      }
    },
    async searchProducts(text) {
      this.loading = true;
      if (text !== "") {
        const filtersObject = {
          promocode: text.trim(),
          // isInStock: 0,
        };
        try {
          this.loading = true;
          const res = await providersService.getProviderProducts(filtersObject);
          if (res) {
            this.fetchedProducts = res.data.data;
            this.loading = false;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      this.loading = false;
    },
    async searchPromocodes(text) {
      this.loading = true;
      if (text !== "") {
        const filtersObject = {
          promocode: text.trim(),
          is_target: true,
          // isInStock: 0,
        };
        try {
          this.loading = true;
          const res = await promocodesService.fetchPromocodes(1, filtersObject);
          if (res) {
            this.fetchedPromocodes = res.promocodesList;
            this.loading = false;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      this.loading = false;
    },
    productChosen() {
      this.dropdownChanged("product_id", ["unit_id"]);
    },

    dataEraser(keysArray) {
      keysArray.forEach((singleKey) => {
        if (
          singleKey == "included_providers" ||
          singleKey == "excluded_providers"
        ) {
          this.dialogFormData[singleKey] = [];
          this.dialogFormData.targetsAreas=[]
          // this.dialogFormData.selectAllSuppliersFlag = true;
        } else {
          if (singleKey == "discrepancy" || singleKey == "min_order_amount") {
            this.dialogFormData[singleKey] = 0;
          } else {
            this.dialogFormData[singleKey] = null;
          }
        }

        // if (singleKey == "product_id") {
        //   this.fetchedProducts = [];
        // }
        // if (singleKey == "unit_id") {
        //   this.fetchedProductUnits = [];
        // }
      });
    },
    promoterTypeChanged(newType) {
      this.dropdownChanged("promoter", []);
      if (newType == "fatura") {
        this.dialogFormData.entity_amount = 0;
      }
      if (newType == "whs") {
        this.dialogFormData.fatura_amount = 0;
      }
    },
    onEditorLoad(editor, target) {
      // To fix a bug in the editor load event called when editor  not fully loaded
      setTimeout(() => {
        editor.insertToolbarItem(
          { groupIndex: 0, itemIndex: 4 },
          {
            el: createLastButton(() => {
              if (target == "markdownEditorConditions") {
                this.pickerShown = !this.pickerShown;
                return;
              }
            }),
            tooltip: "Emoji",
            style: "color:black;",
          }
        );
      }, 0);
    },
    addEmoji(emoji, target) {
      this.$refs[target].invoke("insertText", emoji.native);
      if (target == "markdownEditorConditions") {
        this.pickerShown = false;
        return;
      }
    },
    dropdownChanged(key, clearArray, obj) {
       this.$refs.targetsCreateFromRef.validateField(key);
      this.dataEraser(clearArray);
      if (key === "excluded_providers" || key === "included_providers") {
        
        this.filterAreasByWhs(obj);
      }
    },

    async filterAreasByWhs(obj) {
      try {
        const {
          data: { data },
        } = await targetsService.fetchAreaByProviders({ providers: obj });

        this.dialogFormData.areasList = data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        this.dialogFormData.targetsAreas = data.map((item) => item.id);
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    buildRequestBody() {
      let apiObject = {};

      let conditionsMarkdown =
        this.$refs.markdownEditorConditions.invoke("getMarkdown");
      if (conditionsMarkdown.length) {
        this.dialogFormData.conditions = conditionsMarkdown;
      } else {
        throw {
          response: {
            data: { error: "'Target Conditions' is a required field" },
          },
        };
      }

      let formData = JSON.parse(JSON.stringify(this.dialogFormData));

      apiObject = {
        type: formData.type,
        value: formData.value,
        sections: formData.sections,
        start_date: formData.start_date,
        end_date: formData.end_date,
        gift_type:
          formData.target_gift_type == "static"
            ? formData.gift_type
            : undefined,
        payment_method: formData.payment_method,
        target_gift_type: formData.target_gift_type,
        number_of_days:
          formData.target_gift_type === "dynamic" &&
          formData.duration === "countOfDays"
            ? formData.number_of_days
            : undefined,
        dynamic_promocode_gift_redemption:
          formData.target_gift_type === "dynamic"
            ? formData.dynamic_promocode_gift_redemption
            : undefined,
        dynamic_promocode_start_date:
          formData.target_gift_type === "dynamic" &&
          formData.duration === "specificPeriod"
            ? formData.dynamic_promocode_start_date
            : undefined,
        dynamic_promocode_end_date:
          formData.target_gift_type === "dynamic" &&
          formData.duration === "specificPeriod"
            ? formData.dynamic_promocode_end_date
            : undefined,
        promocode_min_amount:
          formData.target_gift_type === "dynamic"
            ? formData.promocode_min_amount
            : undefined,
        promocode_value:
          formData.target_gift_type === "dynamic"
            ? formData.promocode_value
            : undefined,

        multi_achieved:
          formData.target_gift_type === "dynamic"
            ? formData.multi_achieved
            : undefined,
        areas:
          formData.target_gift_type === "dynamic"
            ? formData.targetsAreas
            : undefined,
        t0NewUsers:
          formData.target_gift_type === "dynamic"
            ? formData.t0NewUsers
            : undefined,
      };

      if (formData.selectAllSuppliersFlag) {
        if (formData.excluded_providers?.length) {
          apiObject.excluded_providers = formData.excluded_providers;
        }
      } else {
        if (formData.included_providers?.length) {
          apiObject.included_providers = formData.included_providers;
        } else {
          throw {
            response: {
              data: { error: "'Assign to Suppliers' cannot be empty" },
            },
          };
        }
      }

      if (formData.type == "receipt_value") {
        apiObject.discrepancy = formData.discrepancy;
      } else {
        if (!formData.min_order_amount.length)
          throw {
            response: {
              data: { error: "'Min. Order' is a required field in this case" },
            },
          };
        apiObject.min_order_amount = parseInt(formData.min_order_amount);
      }
      if (formData.description?.length) {
        apiObject.description = formData.description;
      }
      if (formData.conditions?.length) {
        apiObject.conditions = formData.conditions;
      }
      if (
        formData.gift_type &&
        formData.gift_type == "promocode" &&
        formData.target_gift_type == "static"
      ) {
        if (formData.promocode_id)
          apiObject.promocode_id = formData.promocode_id;
      } else {
        if (
          formData.gift_type &&
          formData.gift_type == "physical" &&
          formData.target_gift_type == "static"
        ) {
          if (!formData.gift_text || !formData.gift_image) {
            throw {
              response: {
                data: {
                  error:
                    "'Text' and 'Image' are both required for 'Physical Gift'",
                },
              },
            };
          } else {
            apiObject.gift_details = {
              text: formData.gift_text,
              image: formData.gift_image,
            };
          }
        }
      }
      if (formData.icon) {
        apiObject.icon = formData.icon;
      }
      // if (
      //   apiObject.hasOwnProperty("min_per_order") &&
      //   apiObject.min_per_order === ""
      // ) {
      //   apiObject.min_per_order = null;
      // }

      return apiObject;
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    wholesalersLookup() {
      let targetLookup =
        this.lookups && this.lookups["distributers"]
          ? this.lookups["distributers"]
          : [];
      let returnedArray = targetLookup.map((item) => {
        if (item.id && item.name) {
          return {
            key: item.id,
            label: item.name,
          };
        }
        return {
          key: "",
          label: "",
        };
      });
      return returnedArray;
    },
    discrepancyAmount() {
      if (
        !(
          this.dialogFormData.type &&
          this.dialogFormData.type == "receipt_value"
        )
      )
        return null;
      let calculatedValue = null;

      try {
        if (!this.dialogFormData.discrepancy || !this.dialogFormData.value)
          return null;
        calculatedValue =
          (this.dialogFormData.discrepancy / this.dialogFormData.value) * 100;
        console.log("calculatedValue :>> ", calculatedValue);

        if (calculatedValue > 5) calculatedValue = Math.ceil(calculatedValue);
        else calculatedValue = null;
      } catch (error) {
        console.log("error :>> ", error);
        calculatedValue = null;
      }
      return calculatedValue;
    },
    allSections() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAvailableSections =
        lookups && lookups.sections ? lookups.sections : [];
      currentAvailableSections.forEach((singleSection) => {
        singleSection.value = singleSection.title;
      });
      return currentAvailableSections;
    },
  },
};
</script>

<style lang="scss">
.targets-dialog__discrepancy-message {
  text-align: center;
  color: red;
  margin-bottom: 15px;
}
.custom-header {
  height: unset !important;
  padding: 12px 0;
  padding: relative;
  .el-divider--horizontal {
    margin: 10px 0;
  }
  h2 {
    margin: unset !important;
  }
}
.targetRegionsWrapper {
  width: 100%;
}
</style>
