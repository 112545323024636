<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- tabs area -->
    <!-- ************************************************************************************ -->
    <el-row type="flex" justify="center">
      <el-col :span="12">
        <el-tabs v-model="activeTabName" @tab-click="tabClicked" stretch>
          <el-tab-pane
            v-for="item in navigationItems"
            :label="item.tabLabel"
            :name="item.tabName"
            :key="item.tabLabel"
          ></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- ************************************************************************************ -->
    <!-- filters area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            :meta="metric.meta ? metric.meta : null"
            @change="filtersChanged(metric.key, $event)"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <TargetsSheetsHandler
      :chosenFilters="{ ...filters, tab: activeTabName }"
      @dataUpdated="getTargets(currentPage, filters)"
    />
    <div class="u-display-flex u-justify-content--end u-bottom-margin--2x">
      <el-button type="primary" @click="showTargetsDialog = true">
        Add Target
      </el-button>
    </div>
    <!-- ************************************************************************************ -->
    <!-- table area -->
    <!-- ************************************************************************************ -->
    <template>
      <el-table :data="targetsList" border stripe>
        <el-table-column prop="id" :label="`Target ID`" />
        <el-table-column prop="type" :label="`Type`" />
        <el-table-column prop="value" :label="`Target Value`" />
        <el-table-column prop="min_order_amount" :label="`Min. Order`" />
        <el-table-column prop="discrepancy" :label="`Discrepancy`" />
        <el-table-column prop="payment_method" :label="`Payment Method`" />

        <el-table-column prop="start_date" :label="`Start Date`">
          <template slot-scope="prop">
            <div>{{ prop.row.start_date | moment("DD/MM/YYYY HH:mm:ss") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="end_date" :label="`End Date`">
          <template slot-scope="prop">
            <div>{{ prop.row.end_date | moment("DD/MM/YYYY HH:mm:ss") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="conditions" :label="`Conditions`" />
        <el-table-column prop="gift_type" :label="`Gift Type`" />
        <el-table-column prop="icon" :label="`Icon`" />
        <!-- <el-table-column prop="description" :label="`Description`" /> -->
        <el-table-column prop="sections" :label="`Sections`">
          <template slot-scope="scope">
            <div>
              <el-button
                circle
                @click="sectionsButtonClicked(scope.row.sections)"
              >
                {{ scope.row.sections.length }}</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="suppliers_number" :label="`Suppliers`">
          <template slot-scope="scope">
            <div>
              <el-button @click="suppliersButtonClicked(scope.row)"
                >List</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operations" :label="$t('GlobalOperationsLabel')">
          <template slot-scope="props">
            <div class="flex-container">
              <el-select
                :value="props.dropdownSelection"
                filterable
                :multiple="false"
                @input="$emit('input', $event)"
                @change="
                  operationSelected({ operation: $event, row: props.row })
                "
                placeholder="Ops"
              >
                <el-option
                  v-for="option in operationsDropdownData"
                  :key="option"
                  :label="option"
                  :value="option"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.count"
        :current-page="currentPage"
        :page-size="25"
        @current-change="pageChanged"
      ></el-pagination>
    </div>

    <TargetCreateDialog
      v-model="showTargetsDialog"
      @targetCreated="targetCreated"
      :iconConfigList="iconsList"
    />
    <!-- <OffersEditDialog v-model="clickedTarget" @targetEdited="targetEdited" /> -->
    <TargetsDeactivationDialog
      v-model="clickedTargetToDeactivate"
      :possibleReasons="cancellationReasonsConfig"
      @saveClicked="deactivateTarget"
      :tab="activeTabName"
    />
    <TargetEditDialog
      v-model="clickedTarget"
      @targetEdited="targetEdited"
      :tab="activeTabName"
      :iconConfigList="iconsList"
    />

    <el-dialog
      :title="extraDialogData.type == 'sections' ? `Sections` : `Suppliers`"
      :visible.sync="showDialogFlag"
    >
      <template v-if="extraDialogData.type == 'sections'">
        <el-table :data="extraDialogData.data" border stripe>
          <el-table-column prop="key" :label="`Key`" />
          <el-table-column prop="title" :label="`Title`" />
        </el-table>
      </template>
      <template v-else>
        <div v-if="extraDialogData.data">
          <div
            v-if="
              extraDialogData.data.includedProviders &&
              extraDialogData.data.includedProviders.length == 0 &&
              extraDialogData.data.excludedProviders &&
              extraDialogData.data.excludedProviders.length == 0
            "
          >
            <span style="color: black">Included Suppliers: All </span>
          </div>
          <div
            v-if="
              extraDialogData.data.includedProviders &&
              extraDialogData.data.includedProviders.length
            "
          >
            <span style="color: black">Included Suppliers: </span>
            <el-tag
              v-for="item in extraDialogData.data.includedProviders"
              :key="item.entity_id"
              type="info"
              style="margin: 2.5px"
              size="small"
            >
              <span>{{ item.entity_id }}</span>
              <span> : </span>
              <span>{{ item.name }}</span>
            </el-tag>
          </div>
          <div
            v-if="
              extraDialogData.data.excludedProviders &&
              extraDialogData.data.excludedProviders.length
            "
          >
            <span style="color: black">Excluded Suppliers: </span>
            <el-tag
              v-for="item in extraDialogData.data.excludedProviders"
              :key="item.entity_id"
              type="info"
              style="margin: 2.5px"
              size="small"
            >
              <span>{{ item.entity_id }}</span>
              <span> : </span>
              <span>{{ item.name }}</span>
            </el-tag>
          </div>
        </div>
      </template>
    </el-dialog>
    <a style="display: none" ref="single-sheet-download" target="_blank"></a>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
      @click="$event.target.value = ''"
    />
  </div>
</template>

<script>
import { targetsService } from "@/services/targets.service";
import { mapGetters } from "vuex";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { targetsMetrics } from "@/components/transaction/metricboxComponents";
import TargetCreateDialog from "./TargetCreateDialog.vue";
import _ from "lodash";
import TargetsSheetsHandler from "./TargetsSheetsHandler.vue";
import OffersEditDialog from "../Offers/OffersEditDialog.vue";
import { adminActions } from "@/services";
// import OffersDeactivationDialog from "../Offers/OffersDeactivationDialog.vue";
import TargetEditDialog from "./TargetEditDialog.vue";
import {
  operationsDropdownDataMap,
  navigationItems,
  filtersMetrics,
} from "./targets.data";
import TargetsDeactivationDialog from "./TargetsDeactivationDialog";
export default {
  name: "TargetsPage",
  components: {
    MetricFilterBox,
    metricbox,
    ...targetsMetrics,
    TargetsSheetsHandler,
    TargetCreateDialog,
    OffersEditDialog,
    // OffersDeactivationDialog,
    TargetEditDialog,
    TargetsDeactivationDialog,
  },
  data() {
    return {
      activeTabName: "upcoming",
      navigationItems: navigationItems,
      targetsList: [],
      originalData: [],
      pagination: {},
      filters: {},
      metricsList: [],
      metrics: {
        states: filtersMetrics,
      },
      showTargetsDialog: false,
      clickedTarget: null,
      cancellationReasonsConfig: ["first", "second", "third"],
      clickedTargetToDeactivate: null,
      operationsDropdownData: [],
      showDialogFlag: false,
      extraDialogData: {
        data: [],
        type: "sections",
      },
      clickedTargetForSheet: null,
      clickedAction: "",
      iconsList: [],
    };
  },
  methods: {
    //tab methods
    chosenTabHandler() {
      const currentSessionTabs = sessionStorage.getItem("sessionTabs");
      if (currentSessionTabs) {
        let parseData = JSON.parse(currentSessionTabs);
        if (parseData.targetsTabs) {
          this.activeTabName = parseData.targetsTabs;
          return;
        }
      } else {
        const defaultTabName = "upcoming";
        this.activeTabName = defaultTabName;
        this.storeActiveTab(defaultTabName);
      }
    },
    storeActiveTab(value) {
      let currentSessionObject = sessionStorage.getItem("sessionTabs");
      let finalValue;
      if (currentSessionObject) {
        let parseData = JSON.parse(currentSessionObject);
        finalValue = {
          ...parseData,
          targetsTabs: value,
        };
      } else {
        finalValue = { targetsTabs: value };
      }
      sessionStorage.setItem("sessionTabs", JSON.stringify(finalValue));
    },
    tabClicked(eventObject) {
      this.storeActiveTab(eventObject.name);
      this.setOperationsDropdownData();
      this.reloadPage();
    },
    //data methods
    async getTargets(page = 1, chosenFilters) {
      const filters = { ...chosenFilters, tab: this.activeTabName };
      try {
        const response = await targetsService.fetchTargets(page, filters);
        // console.log("response :>> ", JSON.parse(JSON.stringify(response)));
        const responseData = response.data.data;
        this.originalData = JSON.parse(JSON.stringify(responseData.targets));
        this.targetsList = responseData.targets;
        this.pagination = {
          count: responseData.total,
          next: responseData.next,
          pages: responseData.pages,
        };
        return response;
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    //filters methods
    filtersChanged(key, value) {
      if (!(typeof value === "number") && _.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.targets = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.reloadPage();
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.targets = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsTargets", []);
      this.reloadPage();
    },
    saveMetircs() {
      localStorage.setItem("metricsTargets", JSON.stringify(this.metricsList));
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("metricsTargets", JSON.stringify(this.metricsList));
      this.metricsList = [...this.metricsList];
    },
    reloadPage() {
      if (this.$route.fullPath != "/marketing/targets?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getTargets(this.currentPage, { ...this.filters });
    },
    initialFiltersHandler() {
      let filtersSession = sessionStorage.getItem("filter");
      if (filtersSession) {
        filtersSession = JSON.parse(filtersSession);
        if (filtersSession.targets) {
          const filtersObject = filtersSession.targets;
          if (filtersObject && filtersObject.filters)
            this.filters = { ...filtersObject.filters };
        }
      }
      let metricsSession = localStorage.getItem("metricsTargets");
      if (metricsSession) {
        this.metricsList = JSON.parse(metricsSession);
      }
    },
    targetCreated() {
      this.showTargetsDialog = false;
      this.getTargets(this.currentPage, this.filters);
    },
    targetEdited() {
      this.clickedTarget = null;
      this.getTargets(this.currentPage, this.filters);
    },

    async deactivateTarget(returnedObject) {
      try {
        const targetId = returnedObject.id;
        const apiObject = {
          deactivation_reason: returnedObject.deactivation_reason,
        };
        this.$loading();
        const response = await targetsService.updateTargetStatus(
          targetId,
          apiObject
        );
        this.$loading().close();
        if (response && response.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Target deactivated successfully",
            "success",
            2000
          );
          this.clickedTargetToDeactivate = null;
          setTimeout(() => {
            this.getTargets(this.currentPage, this.filters);
          }, 1500);
        }
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
    operationSelected(object) {
      // console.log("object :>> ", JSON.parse(JSON.stringify(object)));
      if (object.operation == "Edit") {
        this.clickedTarget = JSON.parse(JSON.stringify(object.row));
        return;
      }
      if (object.operation == "Deactivate" || object.operation == "Stop") {
        this.clickedTargetToDeactivate = JSON.parse(JSON.stringify(object.row));
        return;
      }
      if (object.operation == "Export") {
        this.handleSingleTargetExport(object.row);
        return;
      }

      this.clickedTargetForSheet = JSON.parse(JSON.stringify(object.row));
      this.clickedAction = object.operation;
      this.$refs["excel-upload-input"].click();
    },
    sectionsButtonClicked(object) {
      this.showDialogFlag = true;
      this.extraDialogData = {
        type: "sections",
        data: JSON.parse(JSON.stringify(object)),
      };
    },
    async suppliersButtonClicked(object) {
      let targetId = object.id;
      try {
        const response = await targetsService.fetchTargetSupliers(targetId);
        const popupData = response?.data?.data;
        this.showDialogFlag = true;
        this.extraDialogData = {
          type: "suppliers",
          data: popupData ? JSON.parse(JSON.stringify(popupData)) : null,
        };
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    setOperationsDropdownData() {
      this.operationsDropdownData =
        operationsDropdownDataMap[this.activeTabName];
    },
    async handleSingleTargetExport(targetObject) {
      const targetId = targetObject.id;
      const exportSheetFilters = {
        id: targetId,
      };
      const action = "update";
      try {
        const response = await targetsService.exportTargetConsumersSheet(
          action,
          exportSheetFilters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["single-sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = "TargetsAssignationExport.xlsx";
          this.$refs["single-sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["single-sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    handleUploadClick() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      try {
        const files = e.target.files;
        const rawFile = files[0]; // only use files[0]
        if (!rawFile) return;
        if (this.isExcel(rawFile)) {
          let newForm = new FormData();
          newForm.append("file", rawFile);
          this.handleSheetImport(newForm);
        } else {
          this.$globalFunctions.popupMessageWrapper(
            "File type should be .xls or .xlsx",
            "error",
            2000
          );
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls)$/.test(file.name);
    },
    async handleSheetImport(formBody) {
      const chosenAction = this.clickedAction;
      let apiAction;
      if (chosenAction == "Assign") {
        apiAction = "assign";
      }
      if (chosenAction == "Append") {
        apiAction = "append";
      }
      if (chosenAction == "Un-Assign") {
        apiAction = "unassign";
      }
      try {
        const response = await targetsService.importTargetConsumersSheet(
          formBody,
          apiAction
        );
        // console.log("response :>> ", response);
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.data.message) {
              this.$globalFunctions.popupMessageWrapper(
                parsedResponse.data.message,
                "success",
                2000
              );
            } else {
              this.$globalFunctions.popupMessageWrapper(
                "Success",
                "success",
                2000
              );
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["single-sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["single-sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["single-sheet-download"].click();
            this.$globalFunctions.popupMessageWrapper(
              "file uploaded successfully, please check the downloaded file",
              "success",
              4000
            );
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$globalFunctions.popupMessageWrapper(
          "Error Occured",
          "error",
          2000
        );
      }
      this.sheetFileList = [];
    },
    async fetchConfigs() {
      try {
        const response = await adminActions.getConfig("targets");
        const configData = response.data?.config;

        if (configData?.targets_cancellation_reasons)
          this.cancellationReasonsConfig =
            configData?.targets_cancellation_reasons;

        if (configData?.targets_icons)
          this.iconsList = configData?.targets_icons;

        // this.cancellationReasonsConfig = response.data.config.targets;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  async created() {
    this.initialFiltersHandler();
    this.chosenTabHandler();
    this.setOperationsDropdownData();
    this.getTargets(this.currentPage, this.filters);
    this.fetchConfigs();
  },
  watch: {
    $route(to) {
      this.getTargets(to.query.page, this.filters);
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? parseInt(currentPageQuery) : 1;
    },
    sectionsLookup() {
      let sectionsLookupArray =
        this.lookups && this.lookups.sections ? this.lookups.sections : [];
      let returnedArray = sectionsLookupArray.map((item) => {
        return {
          key: item.key,
          label: item.title,
        };
      });
      return returnedArray;
    },
  },
};
</script>

<style lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
