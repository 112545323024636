<template>
  <el-dialog
    :title="`${tab == 'upcoming' ? 'Stop' : 'Deactivate'} this target ?`"
    :visible.sync="localVisible"
    @close="closeDialogAction"
    width="30%"
  >
    <!-- <div v-if="value" class="u-bottom-margin--3x">
      {{ `${value.entity.name} - ${value.product.name} - ${value.unit.name}` }}
    </div> -->
    <el-radio-group v-model="radio">
      <template>
        <el-radio
          v-for="item in possibleReasons"
          :key="item"
          class="u-bottom-margin--2x"
          style="display: block"
          :label="item"
          >{{ item }}
        </el-radio>
      </template>
    </el-radio-group>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialogAction">{{
        $t("GlobalsCancelBtn")
      }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "TargetsDeactivationDialog",
  props: {
    value: {
      type: Object,
      required: false,
    },
    possibleReasons: {
      default: () => [],
    },
    tab: {
      type: String,
      required: false,
      default: () => "upcoming",
    },
  },
  data() {
    return {
      localVisible: false,
      radio: null,
    };
  },
  methods: {
    closeDialogAction() {
      this.localVisible = false;
      this.radio = null;
      this.$emit("input", null);
    },
    async saveClicked() {
      const chosenRadioValue = this.radio;
      if (!chosenRadioValue) {
        this.$globalFunctions.popupMessageWrapper(
          this.$t("TransactionDetailsPageStopAutoAssignmentPopupValidation1"),
          "error",
          3000
        );
        return;
      }
      let clickedRow = this.value;
      let apiObject = {
        deactivation_reason: chosenRadioValue,
        id: clickedRow.id,
      };
      this.$emit("saveClicked", apiObject);
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.radio = null;
        this.localVisible = true;
        console.log("newValue :>> ", JSON.parse(JSON.stringify(newValue)));
      } else {
        this.localVisible = false;
      }
    },
    localVisible(value) {
      if (value === false) {
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style></style>
