export const operationsDropdownDataMap = {
  upcoming: ["Edit", "Stop", "Export", "Assign", "Append", "Un-Assign"],
  active: ["Edit", "Deactivate", "Export", "Assign", "Append", "Un-Assign"],
  deactivated: ["Edit", "Export", "Assign", "Append", "Un-Assign"],
};

export const navigationItems = [
  {
    tabLabel: "Upcoming",
    tabName: "upcoming",
  },
  {
    tabLabel: "Active",
    tabName: "active",
  },
  {
    tabLabel: "Deactivated",
    tabName: "deactivated",
  },
];
export const receiptValueDropdownData = [
  {
    label: "Receipt Value",
    value: "receipt_value",
  },
  {
    label: "Receipt Count",
    value: "receipt_count",
  },
];

export const gitTypeValueDropdownData = [
  {
    label: "Dynamic Gift",
    value: "dynamic",
  },
  {
    label: "Static Gift",
    value: "static",
  },
];
export const paymentMethodDropdownData = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "Both",
    value: "both",
  },
];
export const filtersMetrics = [
  {
    key: "supplier_ids",
    label: "Suppliers",
    compName: "localSearchMetric",
    meta: {
      placeholder: "Supplier Name",
      multiple: true,
      lookupKey: "distributers",
    },
  },
  {
    key: "section_keys",
    label: "Sections",
    compName: "providerTypeMetric",
    meta: {
      multiple: true,
    },
  },
  {
    key: "payment_method",
    label: "Payment Method",
    compName: "dynamicDropdownMetric",
    meta: {
      data: paymentMethodDropdownData.map((item) => {
        return {
          key: item.value,
          label: item.label,
        };
      }),
      placeholder: "Select Type",
      // multiple: true,
    },
  },
  {
    key: "gift_type",
    label: "Gift Type",
    compName: "dynamicDropdownMetric",
    meta: {
      data: [
        {
          key: "promocode",
          label: "Promocode",
        },
        {
          key: "physical",
          label: "Physical",
        },
      ],
      placeholder: "Select Type",
      multiple: true,
    },
  },
  {
    key: "type",
    label: "Target Type",
    compName: "dynamicDropdownMetric",
    meta: {
      data: receiptValueDropdownData.map((item) => {
        return {
          key: item.value,
          label: item.label,
        };
      }),
      placeholder: "Select Type",
      // multiple: true,
    },
  },
  {
    key: "id",
    label: "Target ID",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Number",
    },
  },
  {
    key: "min_value",
    label: "Target Value From",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Number",
    },
  },
  {
    key: "max_value",
    label: "Target Value To",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Number",
    },
  },
  {
    key: "start_date",
    label: "TransactionPageStartDateFilterLabel",
    compName: "startDateMetric",
  },
  {
    key: "end_date",
    label: "TransactionPageEndDateFilterLabel",
    compName: "endDateMetric",
  },
];

export const targetIconDropdownData = [
  {
    label: "Image1",
    value: "Image1",
  },
  {
    label: "Image2",
    value: "Image2",
  },
  {
    label: "Image3",
    value: "Image",
  },
];
export const giftTypeDropdownData = [
  {
    label: "Promo code",
    value: "promocode",
  },
  {
    label: "Physical Gift",
    value: "physical",
  },
];

export const defaultCreateData = {
  type: null,
  discrepancy: 0,
  min_order_amount: 0,
  value: null,
  payment_method: null,
  sections: [],
  selectAllSuppliersFlag: true,
  included_providers: [],
  excluded_providers: [],
  start_date: null,
  end_date: null,
  icon: null,
  gift_type: null,
  promocode_id: null,
  promocode_validity: null,
  description: null,
  conditions: null,
  t0NewUsers: false,
  targetsAreas: [],
  areasList: [],
  duration: "countOfDays",
  dynamic_promocode_gift_redemption: "all",
};
export const editorOptions = {
  minHeight: "200px",
  useDefaultHTMLSanitizer: true,
  useCommandShortcut: false,
  hideModeSwitch: true,
  toolbarItems: [[]],
  autofocus: false,
};
