<template>
  <div>
    <el-dialog :title="`Edit Offer`" :visible.sync="localVisible">
      <el-form :rules="rules" :model="dialogFormData" ref="targetEditDialogRef">
        <template v-if="tab == 'upcoming'">
          <div>
            <div style="display: flex">
              <el-form-item
                :label="`Start Date`"
                prop="start_date"
                label-width="160px"
                style="flex-basis: 50%"
              >
                <el-date-picker
                  v-model="dialogFormData.start_date"
                  type="datetime"
                  placeholder="Select date and time"
                  value-format="timestamp"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item
                label-width="160px"
                :label="`End Date`"
                prop="end_date"
                style="flex-basis: 50%"
              >
                <el-date-picker
                  v-model="dialogFormData.end_date"
                  type="datetime"
                  placeholder="Select date and time"
                  value-format="timestamp"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <el-form-item
              :label="`Target Type`"
              prop="type"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.type"
                clearable
                filterable
                style="width: 100%"
                @change="
                  dropdownChanged('type', ['discrepancy', 'min_order_amount'])
                "
              >
                <el-option
                  v-for="p in receiptValueDropdownData"
                  :key="p.value"
                  :label="p.label"
                  :value="p.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="`Discrepancy`"
              prop="discrepancy"
              label-width="160px"
            >
              <el-input
                type="number"
                v-model="dialogFormData.discrepancy"
                autocomplete="off"
                :disabled="
                  !(
                    dialogFormData.type &&
                    dialogFormData.type == 'receipt_value'
                  )
                "
                @change="dropdownChanged('discrepancy', [])"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              :label="`Min. Order`"
              prop="min_order_amount"
              label-width="160px"
            >
              <el-input
                type="number"
                v-model="dialogFormData.min_order_amount"
                autocomplete="off"
                :disabled="
                  !(
                    dialogFormData.type &&
                    dialogFormData.type == 'receipt_count'
                  )
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item
              :label="`Target Value`"
              prop="value"
              label-width="160px"
            >
              <el-input
                type="number"
                v-model="dialogFormData.value"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
            <div
              v-if="discrepancyAmount"
              class="targets-dialog__discrepancy-message"
            >
              NB: The Discrepancy between the Target Value and Discrepancy is
              {{ discrepancyAmount }}%
            </div>
            <el-form-item
              :label="`Payment Method`"
              prop="payment_method"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.payment_method"
                clearable
                filterable
                style="width: 100%"
                @change="dropdownChanged('payment_method', [])"
              >
                <el-option
                  v-for="p in paymentMethodDropdownData"
                  :key="p.value"
                  :label="p.label"
                  :value="p.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="`Sections`"
              prop="sections"
              label-width="160px"
            >
              <dropdownSelectAll
                ref="wholesalersDropdown"
                v-model="dialogFormData.sections"
                :source="allSections"
                :placeholder="`Select Sections`"
                @change="sectionsChosen"
              >
                <template v-slot:optionBody>
                  <el-option
                    v-for="item in allSections"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </template>
              </dropdownSelectAll>
            </el-form-item>

            <el-form-item
              :label="`Gift Type`"
              prop="gift_type"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.gift_type"
                clearable
                filterable
                style="width: 100%"
                @change="dropdownChanged('gift_type', [])"
              >
                <el-option
                  v-for="p in giftTypeDropdownData"
                  :key="p.value"
                  :label="p.label"
                  :value="p.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                dialogFormData.gift_type &&
                dialogFormData.gift_type == 'promocode'
              "
              :label="`Promocode`"
              prop="promocode_id"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.promocode_id"
                clearable
                filterable
                remote
                :remote-method="searchPromocodes"
                :loading="loading"
                style="width: 100%"
              >
                <el-option
                  v-for="p in fetchedPromocodes"
                  :key="p.id"
                  :label="p.code"
                  :value="p.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <template
              v-if="
                dialogFormData.gift_type &&
                dialogFormData.gift_type == 'physical'
              "
            >
              <el-form-item
                :label="`Text`"
                prop="gift_text"
                label-width="160px"
              >
                <el-input
                  v-model="dialogFormData.gift_text"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                :label="`Image`"
                prop="gift_image"
                label-width="160px"
              >
                <el-input
                  v-model="dialogFormData.gift_image"
                  autocomplete="off"
                />
              </el-form-item>
            </template>
            <el-form-item
              :label="`Select all Suppliers`"
              prop="select_all_suppliers"
              label-width="200px"
            >
              <el-switch
                @change="
                  dataEraser(['included_providers', 'excluded_providers'])
                "
                v-model="dialogFormData.selectAllSuppliersFlag"
              ></el-switch>
            </el-form-item>
            <el-form-item
              :label="`Assign to Suppliers`"
              prop="included_providers"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.included_providers"
                clearable
                filterable
                remote
                multiple
                :remote-method="filterData"
                :loading="loading"
                style="width: 100%"
                @change="dropdownChanged('included_providers', [])"
                :disabled="dialogFormData.selectAllSuppliersFlag"
              >
                <el-option
                  v-for="p in filteredSuppliersData"
                  :key="p.id"
                  :label="p.name"
                  :value="p.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="`Excluded Suppliers`"
              prop="excluded_providers"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.excluded_providers"
                clearable
                filterable
                remote
                multiple
                :remote-method="filterData"
                :loading="loading"
                style="width: 100%"
                @change="dropdownChanged('excluded_providers', [])"
                :disabled="!dialogFormData.selectAllSuppliersFlag"
              >
                <el-option
                  v-for="p in filteredSuppliersData"
                  :key="p.id"
                  :label="p.name"
                  :value="p.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </template>
        <template v-if="tab == 'active' || tab == 'upcoming'">
          <div>
            <el-form-item
              :label="`Target Icon`"
              prop="icon"
              label-width="160px"
            >
              <el-select
                v-model="dialogFormData.icon"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="(p, index) in targetIconDropdownData"
                  :key="index"
                  :label="p"
                  :value="p"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="`Target Conditions`"
              prop="conditions"
              label-width="160px"
            >
              <Picker
                :native="true"
                :showCategories="true"
                :showPreview="true"
                :style="{
                  position: 'absolute',
                  left: ' 20%',
                  'z-index': '99999',
                  display: pickerShown ? 'flex' : 'none',
                }"
                @select="addEmoji($event, 'markdownEditorConditions')"
              />
              <Editor
                @load="onEditorLoad($event, 'markdownEditorConditions')"
                ref="markdownEditorConditions"
                height="250px"
                initialEditType="wysiwyg"
                :options="editorOptions"
              />
            </el-form-item>
            <el-form-item
              :label="`Description`"
              prop="description"
              label-width="160px"
            >
              <el-input v-model="dialogFormData.description" autocomplete="off">
              </el-input>
            </el-form-item>
          </div>
        </template>
      </el-form>
      <!-- <template v-else-if="tab == 'upcoming'">
        <div>upcoming</div>
      </template>
      <template v-else-if="tab == 'active'">
        <div>active</div>
      </template>
      <template v-else-if="tab == 'deactivated'">
        <div>deactivated</div>
      </template>
      <div v-else>trial</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="saveEditTarget">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { offersService } from "@/services/offers.service.js";
import { mapGetters } from "vuex";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import { Picker } from "emoji-mart-vue";
import {
  receiptValueDropdownData,
  paymentMethodDropdownData,
  // targetIconDropdownData,
  giftTypeDropdownData,
  defaultCreateData,
  editorOptions,
} from "./targets.data";

// import customValidators from "../../../utils/customValidators";
import dropdownSelectAll from "../../../components/dropdownSelectAll.vue";
import { promocodesService } from "@/services/promocodes.service";
import { targetsService } from "@/services/targets.service";
import { sectionsService } from "@/services/sections.service";

function createLastButton(handler) {
  const button = document.createElement("button");
  button.className = "toastui-editor-toolbar-icons last";
  button.style.backgroundImage = "none";
  button.style.margin = "0";
  button.innerHTML = `<i class="el-icon-bicycle"></i>`;
  button.addEventListener("click", (e) => {
    handler();
    e.preventDefault();
  });
  return button;
}

export default {
  name: "TargetEditDialog",
  components: {
    Editor,
    Picker,
    dropdownSelectAll,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    tab: {
      type: String,
      default: "upcoming",
    },
    iconConfigList: {
      default: () => [],
    },
  },
  data() {
    return {
      localVisible: false,

      rules: {},

      dialogFormData: null,
      filteredWhsData: [],
      loading: false,
      fetchedProducts: [],
      fetchedProductUnits: [],

      pickerShown: false,

      communicationTypes: ["SMS", "Notification", "Both"],
      receiptValueDropdownData,
      paymentMethodDropdownData,
      targetIconDropdownData: [],
      giftTypeDropdownData,
      defaultCreateData,
      editorOptions,
      filteredSuppliersData: [],
      fetchedPromocodes: [],
      originalData: null,
    };
  },
  watch: {
    localVisible(value) {
      if (value === false) {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        try {
          this.$refs["targetEditDialogRef"].resetFields();
          this.$refs.markdownEditorConditions.invoke("setMarkdown", "");
          // this.$refs.markdownEditorDescription.invoke("setMarkdown", "");
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.originalData = null;
        this.$emit("input", null);
      }
    },
    async value(newVal) {
      if (newVal) {
        this.localVisible = true;
        this.targetIconDropdownData = this.iconConfigList;
        this.targetClicked(newVal);
      } else this.localVisible = false;
    },
  },
  created() {
    this.dialogFormData = { ...this.defaultCreateData };
    this.localVisible = false;
  },
  methods: {
    async sectionsChosen(obj) {
      try {
        setTimeout(() => {
          this.dropdownChanged("sections", [
            "included_providers",
            "excluded_providers",
          ]);
        }, 100);
        let filterBody = {
          sectionKeys: obj,
        };
        const response = await sectionsService.getSectionsProviders(filterBody);
        const fetchedProviders = response.data?.data?.providers;
        this.possibleSuppliers = fetchedProviders
          ? JSON.parse(JSON.stringify(fetchedProviders))
          : [];
        this.filteredSuppliersData = fetchedProviders
          ? JSON.parse(JSON.stringify(fetchedProviders))
          : [];
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async saveEditTarget() {
      this.$refs["targetEditDialogRef"].validate(async (isValid) => {
        try {
          if (isValid) {
            const apiObject = this.buildRequestBody();
            const targetId = this.dialogFormData.id;

            if (
              apiObject.sections ||
              apiObject.included_providers ||
              apiObject.excluded_providers ||
              apiObject.payment_method ||
              apiObject.start_date ||
              apiObject.end_date
            ) {
              const confirm = window.confirm(
                "The Edit Action will unassign the target retailer list. Please, make sure to export the users and re-assign again "
              );
              if (!confirm) return;
            }

            const response = await targetsService.editTarget(
              targetId,
              apiObject
            );
            if (response && response.status && response.status == 200) {
              this.$globalFunctions.popupMessageWrapper(
                "Target edited successfully",
                "success",
                1500
              );
              setTimeout(() => {
                this.$refs.markdownEditorConditions.invoke("setMarkdown", "");
                // this.$refs.markdownEditorDescription.invoke("setMarkdown", "");
                this.$emit("targetEdited");
              }, 1300);
            }
          }
        } catch (error) {
          console.log("saveEditTargetError", error);
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
    },
    buildRequestBody() {
      let apiObject = {};

      const oldData = JSON.parse(JSON.stringify(this.originalData));
      const newData = JSON.parse(JSON.stringify(this.dialogFormData));

      if (this.tab == "upcoming") {
        if (newData.start_date != oldData.start_date)
          apiObject.start_date = newData.start_date;
        if (newData.end_date != oldData.end_date)
          apiObject.end_date = newData.end_date;
        if (newData.payment_method != oldData.payment_method)
          apiObject.payment_method = newData.payment_method;
        if (newData.type != oldData.type) apiObject.type = newData.type;
        if (newData.value != oldData.value) apiObject.value = newData.value;
        if (newData.min_order_amount != oldData.min_order_amount)
          apiObject.min_order_amount = newData.min_order_amount;
        if (newData.discrepancy != oldData.discrepancy)
          apiObject.discrepancy = newData.discrepancy;
        if (newData.gift_type != oldData.gift_type)
          apiObject.gift_type = newData.gift_type;

        if (newData.gift_type && newData.gift_type == "promocode") {
          apiObject.promocode_id = newData.promocode_id;
        } else {
          if (newData.gift_type && newData.gift_type == "physical") {
            if (!newData.gift_text || !newData.gift_image) {
              throw {
                response: {
                  data: {
                    error:
                      "'Text' and 'Image' are both required for 'Physical Gift'",
                  },
                },
              };
            } else {
              apiObject.gift_details = {
                text: oldData.gift_text,
                image: oldData.gift_image,
              };
              if (newData.gift_text != oldData.gift_text)
                apiObject.gift_details.text = newData.gift_text;
              if (newData.gift_image != oldData.gift_image);
              apiObject.gift_details.image = newData.gift_image;
            }
          }
        }

        const oldSections = oldData.sections.sort();
        const newSections = newData.sections.sort();

        if (newSections.toString() != oldSections.toString())
          apiObject.sections = newData.sections;

        const numberSorter = (a, b) => a - b;
        const oldDataExcludedProviders =
          oldData.excluded_providers.sort(numberSorter);

        const oldDataIncludedProviders =
          oldData.included_providers.sort(numberSorter);

        const newDataIncludedProviders =
          newData.included_providers.sort(numberSorter);

        if (!newData.selectAllSuppliersFlag) {
          if (!newData.included_providers?.length) {
            throw {
              response: {
                data: { error: "'Assign to Suppliers' cannot be empty" },
              },
            };
          }
        }
        const newDataExcludedProviders =
          newData.excluded_providers.sort(numberSorter);
        if (
          !(
            JSON.stringify(oldDataExcludedProviders) ==
              JSON.stringify(newDataExcludedProviders) &&
            JSON.stringify(oldDataIncludedProviders) ==
              JSON.stringify(newDataIncludedProviders)
          )
        ) {
          if (
            newDataIncludedProviders.length == 0 &&
            newDataExcludedProviders.length == 0
          ) {
            apiObject.included_providers = [];
            apiObject.excluded_providers = [];
          } else {
            if (
              JSON.stringify(oldDataExcludedProviders) !=
              JSON.stringify(newDataExcludedProviders)
            ) {
              apiObject.excluded_providers = newDataExcludedProviders;
            }
            if (
              JSON.stringify(oldDataIncludedProviders) !=
              JSON.stringify(newDataIncludedProviders)
            ) {
              apiObject.included_providers = newDataIncludedProviders;
            }
          }
        }
      }

      if (newData.icon != oldData.icon) apiObject.icon = newData.icon;
      if (newData.description != oldData.description)
        apiObject.description = newData.description;

      let conditionsMarkdown =
        this.$refs.markdownEditorConditions.invoke("getMarkdown");
      if (conditionsMarkdown.length) newData.conditions = conditionsMarkdown;
      else {
        throw {
          response: {
            data: {
              error: "'Target Conditions' is a required field",
            },
          },
        };
      }

      if (newData.conditions != oldData.conditions)
        apiObject.conditions = newData.conditions;

      return apiObject;
    },
    dataEraser(keysArray) {
      keysArray.forEach((singleKey) => {
        if (
          singleKey == "included_providers" ||
          singleKey == "excluded_providers"
        ) {
          this.dialogFormData[singleKey] = [];
          // this.dialogFormData.selectAllSuppliersFlag = true;
        } else {
          if (singleKey == "discrepancy" || singleKey == "min_order_amount") {
            this.dialogFormData[singleKey] = 0;
          } else {
            this.dialogFormData[singleKey] = null;
          }
        }
      });
    },

    onEditorLoad(editor, target) {
      // To fix a bug in the editor load event called when editor  not fully loaded
      setTimeout(() => {
        editor.insertToolbarItem(
          { groupIndex: 0, itemIndex: 4 },
          {
            el: createLastButton(() => {
              if (target == "markdownEditorConditions") {
                this.pickerShown = !this.pickerShown;
                return;
              }
            }),
            tooltip: "Emoji",
            style: "color:black;",
          }
        );
      }, 0);
    },
    addEmoji(emoji, target) {
      this.$refs[target].invoke("insertText", emoji.native);
      if (target == "markdownEditorConditions") {
        this.pickerShown = false;
        return;
      }
    },
    dropdownChanged(key, clearArray) {
      this.$refs.targetEditDialogRef.validateField(key);
      this.dataEraser(clearArray);
    },
    async searchPromocodes(text) {
      this.loading = true;
      if (text !== "") {
        const filtersObject = {
          promocode: text.trim(),
          is_target: true,
          // isInStock: 0,
        };
        try {
          this.loading = true;
          const res = await promocodesService.fetchPromocodes(1, filtersObject);
          if (res) {
            this.fetchedPromocodes = res.promocodesList;
            this.loading = false;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      this.loading = false;
    },
    async targetClicked(newValue) {
      let dataHolder = {
        ...newValue,
      };
      dataHolder.sections = dataHolder.sections.map((item) => {
        return item.key;
      });
      setTimeout(() => {
        if (newValue.conditions) {
          this.$refs.markdownEditorConditions.invoke(
            "setMarkdown",
            newValue.conditions
          );
        }
      }, 10);
      if (this.tab == "upcoming") {
        try {
          let filterBody = {
            sectionKeys: dataHolder.sections,
          };

          const fetchPossibleSuppliersFromSections =
            sectionsService.getSectionsProviders(filterBody);
          const fetchTargetSuppliers = targetsService.fetchTargetSupliers(
            newValue.id
          );
          const responses = await Promise.all([
            fetchPossibleSuppliersFromSections,
            fetchTargetSuppliers,
          ]);
          const fetchedPossibleSuppliers = responses[0].data?.data?.providers;
          this.possibleSuppliers = fetchedPossibleSuppliers
            ? JSON.parse(JSON.stringify(fetchedPossibleSuppliers))
            : [];
          this.filteredSuppliersData = fetchedPossibleSuppliers
            ? JSON.parse(JSON.stringify(fetchedPossibleSuppliers))
            : [];
          let fetchedProviders = responses[1].data?.data;
          if (fetchedProviders) {
            const fetched_included_providers =
              fetchedProviders.includedProviders;
            const fetched_excluded_providers =
              fetchedProviders.excludedProviders;
            if (!fetched_included_providers.length) {
              dataHolder.selectAllSuppliersFlag = true;
            }
            dataHolder.included_providers = fetched_included_providers.map(
              (item) => {
                return item.entity_id;
              }
            );
            dataHolder.excluded_providers = fetched_excluded_providers.map(
              (item) => {
                return item.entity_id;
              }
            );
            if (dataHolder.gift_details) {
              dataHolder.gift_text = dataHolder.gift_details.text;
              dataHolder.gift_image = dataHolder.gift_details.image;
            }
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }

      this.dialogFormData = dataHolder;
      this.originalData = JSON.parse(JSON.stringify(dataHolder));
    },
    filterData(text) {
      try {
        this.loading = true;
        let finalArray = this.possibleSuppliers.filter((singleItem) => {
          if (text.length > 0) {
            return singleItem.name.toLowerCase().match(text.toLowerCase());
          }
          return false;
        });
        this.filteredSuppliersData = finalArray;
        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    allSections() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAvailableSections =
        lookups && lookups.sections ? lookups.sections : [];
      currentAvailableSections.forEach((singleSection) => {
        singleSection.value = singleSection.title;
      });
      return currentAvailableSections;
    },
    discrepancyAmount() {
      if (
        !(
          this.dialogFormData.type &&
          this.dialogFormData.type == "receipt_value"
        )
      )
        return null;
      let calculatedValue = null;

      try {
        if (!this.dialogFormData.discrepancy || !this.dialogFormData.value)
          return null;
        calculatedValue =
          (this.dialogFormData.discrepancy / this.dialogFormData.value) * 100;
        console.log("calculatedValue :>> ", calculatedValue);

        if (calculatedValue > 5) calculatedValue = Math.ceil(calculatedValue);
        else calculatedValue = null;
      } catch (error) {
        console.log("error :>> ", error);
        calculatedValue = null;
      }
      return calculatedValue;
    },
  },
};
</script>

<style lang="scss">
.targets-dialog__discrepancy-message {
  text-align: center;
  color: red;
  margin-bottom: 15px;
}
</style>
