<template>
  <div>
    <div
      class="buttons-container u-display-flex u-justify-content--between u-align-items--center u-bottom-margin--2x"
    >
      <el-upload
        class="u-right-margin--2x u-left-margin--2x"
        :on-change="uploadSheetFileChanged"
        :on-remove="removeSheetFile"
        action="#"
        :multiple="false"
        :auto-upload="false"
        :file-list="sheetFileList"
        accept=".xlsx, .csv"
      >
        <el-button slot="trigger" size="mini" type="primary">
          {{ $t("GlobalSelectFileButtonLabel") }}
        </el-button>
        <el-dropdown size="medium" @command="handleSheetImport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-top"
            type="info"
            plain
            :disabled="sheetFileList.length == 0"
            :title="sheetFileList.length == 0 ? 'true' : 'false'"
          >
            {{ $t("GlobalImportExcelButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ type: 'provider', action: 'create' }"
              >Add Targets</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'provider', action: 'update' }"
              >Update Targets</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'consumer', action: 'assign' }"
              >Assign Consumers</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'consumer', action: 'append' }"
              >Append Consumers</el-dropdown-item
            >
            <el-dropdown-item
              :command="{ type: 'consumer', action: 'unassign' }"
              >Un-Assign Consumers</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-upload>
      <div>
        <el-dropdown size="medium" @command="handleSheetExport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            {{ $t("GlobalExportFileButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ type: 'provider', action: 'update' }"
              >Targets</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'consumer', action: 'update' }"
              >Users</el-dropdown-item
            >
            <!-- <el-dropdown-item command="delete">Delete</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown size="medium" @command="handleSheetExport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            {{ $t("GlobalTemplateDownloadButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ type: 'provider', action: 'create' }"
              >Targets Creation</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'consumer', action: 'create' }"
              >Users Assignation</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
  </div>
</template>

<script>
import { targetsService } from "@/services/targets.service";

export default {
  name: "TargetsSheetsHandler",
  props: {
    chosenFilters: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sheetFileList: [],
    };
  },
  methods: {
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(inputObject) {
      const chosenAction = inputObject.action;
      const type = inputObject.type;
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      try {
        let response;
        if (type == "provider") {
          response = await targetsService.importTargetsSheet(
            toBeSentForm,
            chosenAction
          );
        } else {
          response = await targetsService.importTargetConsumersSheet(
            toBeSentForm,
            chosenAction
          );
        }
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            console.log("parsedResponse", parsedResponse);
            if (parsedResponse.data.message) {
              this.$message({
                message: parsedResponse.data.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
      this.sheetFileList = [];
      this.$emit("dataUpdated");
    },
    async handleSheetExport(exportInputObject) {
      const chosenAction = exportInputObject.action;
      const type = exportInputObject.type;

      try {
        let response;
        if (type == "provider") {
          response = await targetsService.exportTargetsSheet(
            chosenAction,
            this.chosenFilters
          );
        } else {
          response = await targetsService.exportTargetConsumersSheet(
            chosenAction,
            this.chosenFilters
          );
        }

        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = this.setFileName(exportInputObject);
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    setFileName(inputObject) {
      const chosenAction = inputObject.action;
      const isCreate = chosenAction === "create";
      const type = inputObject.type;
      let output;
      if (type == "provider") {
        output = isCreate
          ? "TargetsCreateTemplate.xlsx"
          : `TargetsUpdateSheet.xlsx`;
      } else {
        output = isCreate
          ? "TargetConsumersCreateTemplate.xlsx"
          : `TargetConsumersUpdateSheet.xlsx`;
      }

      return output;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  background-color: white;
  padding: 15px 10px;
  border: 0.5px solid #dcdfe6;
  border-radius: 5px;
}
</style>
