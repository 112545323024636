import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  // if (filters["entity_id"]) {
  //   filters["entity_ids"] = [filters["entity_id"]];
  //   delete filters["entity_id"];
  // }
  // if (filters["areas"]) {
  //   if (filters["areas"].length == 0) {
  //     delete filters.areas;
  //   }
  // }
  // if (filters.start_date === null) {
  //   delete filters.start_date;
  // }
  if (filters.id) {
    filters.id = parseInt(filters.id);
  }
  if (filters.min_value) {
    filters.min_value = parseInt(filters.min_value);
  }
  if (filters.max_value) {
    filters.max_value = parseInt(filters.max_value);
  }
  if (filters.start_date == null) {
    delete filters.start_date;
  }
  if (filters.end_date == null) {
    delete filters.end_date;
  }
  if (filters["start_date"]) {
    filters.start_date = new Date(filters.start_date).getTime();
  }
  if (filters["end_date"]) {
    filters.end_date = new Date(filters.end_date).getTime();
  }
  let filtersCopy = filters;

  return { filtersCopy };
}

function importTargetsSheet(toBeSentFormData, action) {
  // return new Promise(function (resolve) {
  //   setTimeout(() => resolve({ action, toBeSentFormData, status: 200 }), 3000);
  // });
  return $http.post(
    `/admin/targets/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}

function exportTargetsSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  let requestParams = {
    filters: { ...filtersCopy },
    action,
  };
  // return new Promise(function (resolve) {
  //   setTimeout(
  //     () => resolve({ action, filters, requestParams, status: 200 }),
  //     3000
  //   );
  // });
  return $http.get(`/admin/targets/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function addOffer(requestBody) {
  return $http.post(`/admin/targets`, requestBody, {
    headers: authHeader(),
  });
}
function editTarget(target_id, requestBody) {
  // return new Promise(function (resolve) {
  //   setTimeout(() => resolve({ data: { ...requestBody }, target_id }), 1);
  // });
  return $http.patch(`/admin/targets/${target_id}`, requestBody, {
    headers: authHeader(),
  });
}

function fetchTargets(page = 1, filtersObject = {}) {
  const { filtersCopy } = processFilters(filtersObject);
  let requestParams = {
    filters: { ...filtersCopy },
    page,
  };
  return $http.get(`/admin/targets`, {
    params: requestParams,
    headers: authHeader(),
  });
}
function addTarget(requestBody) {
  return $http.post(`/admin/targets`, requestBody, {
    headers: authHeader(),
  });
}
function fetchTargetSupliers(targetId) {
  return $http.get(`/admin/targets/${targetId}/providers`, {
    headers: authHeader(),
  });
}
function updateTargetStatus(targetId, requestBody) {
  // return new Promise(function (resolve) {
  //   setTimeout(() => resolve({ ...requestBody, targetId, status: 200 }), 3000);
  // });
  return $http.put(`/admin/targets/${targetId}/deactivation`, requestBody, {
    headers: authHeader(),
  });
}

function exportTargetConsumersSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  let requestParams = {
    filters: { ...filtersCopy },
    action,
  };
  return $http.get(`/admin/targets/export/consumers`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}
function importTargetConsumersSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/targets/import/consumers?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}

function fetchAreaByProviders(filtersObject = {}) {
  let requestParams = {
    filter: { ...filtersObject },
  };
  return $http.get(`/admin/providers/areas`, {
    params: requestParams,
    headers: authHeader(),
  });
}

export const targetsService = {
  importTargetsSheet,
  exportTargetsSheet,
  addOffer,
  editTarget,
  fetchTargets,
  addTarget,
  fetchTargetSupliers,
  updateTargetStatus,
  exportTargetConsumersSheet,
  importTargetConsumersSheet,
  fetchAreaByProviders,
};
